import { FilesUtils } from '../../../helpers/files.helper'
import { IFiles } from '../../../interfaces/files/files.interface'
import { IMeta } from '../../../interfaces/auth/auth.interface'
import { FileData } from '../../../interfaces/user/user.interface'
import { environment } from '../../../environments/environment'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { Utils } from '../../../helpers/utils.helper'

export enum BannerKey {
  FIRST_DASHBOARD_BANNER = 'firstDashboardBanner',
  SECOND_DASHBOARD_BANNER = 'secondDashboardBanner',
}

export interface AppConfig {
  id: string
  configIndex: number
  firstDashboardBanner: FileData
  secondDashboardBanner: FileData
  meta: IMeta
}

const configIndex = 1

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  baseRoute = `${environment.api}/app-config`

  private appConfigSubject = new Subject<AppConfig>()
  public appConfigSubject$: Observable<AppConfig> =
    this.appConfigSubject.asObservable()

  constructor(
    private http: HttpClient,
    private filesUtils: FilesUtils,
  ) {}

  getAppConfig(utils: Utils) {
    this.http.get<AppConfig>(`${this.baseRoute}/${configIndex}`).subscribe({
      next: (appConfig) => {
        this.appConfigSubject.next(appConfig)
      },
      error: (err) => {
        console.log(err)
        const errorMessage = utils.getErrorMessage(err)
        utils.showErrorMessage('Erro ao fazer upload de arquivos', errorMessage)
      },
    })
  }

  setAppConfig(appConfig: AppConfig) {
    this.appConfigSubject.next(appConfig)
  }

  updateDashboardBanner(
    files: IFiles[],
    bannerKey: BannerKey,
  ): Observable<AppConfig> {
    const formData = this.filesUtils.createFileFormData(files)
    return this.http.post<AppConfig>(
      `${this.baseRoute}/dashboard-banner/${bannerKey}`,
      formData,
    )
  }
}
